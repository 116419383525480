import useFileDownload from "~/composables/useFileDownload";
import type { Schemas } from "~/api-types/storeApiTypes";

const pending = reactive<{ [key: string]: boolean }>({});
export default function () {
  const { apiClient } = useShopwareContext();
  const { pushError } = useNotifications();

  async function getDocumentBlob(document: Schemas["HwDocument"]) {
    try {
      pending[document.id as string] = true;
      const { data } = await apiClient.invoke(
        "downloadDocument post /hw-document/download/{documentId}",
        {
          pathParams: {
            documentId: document.id,
          },
        },
      );

      return data;
    } catch (e) {
      pushError(e.details);
    } finally {
      pending[document.id as string] = false;
    }
  }

  async function downloadDocument(document: Schemas["HwDocument"]) {
    const fileBlob = await getDocumentBlob(document);
    useFileDownload(fileBlob, document.documentMediaFile?.fileName ?? "");
  }

  return {
    downloadDocument,
    getDocumentBlob,
    pending,
  };
}
